@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Plus Jakarta Sans', sans-serif;

  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
input:checked ~ .toggle__line {
  background-color: rgb(255, 255, 255);
}

input:checked ~ .toggle__dot {
  background-color: #f23d00;
  transform: translateX(100%);
}

/* Modal Overlay */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent; /* Set to transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.ReactModal__Content {
  position: relative;
  background: transparent; /* Set to transparent */
  border-radius: 4px;
  padding: 0;
  width: 100%;
  max-width: 800px; /* Adjust for mobile */
  height: auto; /* Allow dynamic height */
  overflow: auto; /* Allow scrolling if needed */
}
.player-search-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3); /* Light semi-transparent background for frosted effect */
  backdrop-filter: blur(30px); /* Strong blur effect for frosted glass */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
  opacity: 0; /* Start hidden for animation */
  animation: fadeIn 0.5s forwards; /* Animation for fade-in effect */
}

.ReactModal__Close {
  display: none; /* Hide the default close button */
}
@keyframes fadeIn {
  from {
    opacity: 0; /* Start fully transparent */
  }
  to {
    opacity: 1; /* End fully opaque */
  }
}

.player-search-overlay {
  background-color: #ff0000; /* Background color for the overlay */
  max-height: 80%; /* Limit the height of the overlay */
  overflow: hidden; /* Prevent overflow */
}

@media (min-width: 640px) {
  .ReactModal__Content {
      width: 90%;
      height: 90%;
  }
}

input[type="range"]::-webkit-slider-thumb {
  width: 25px;
  height: 25px;
  background: #4f46e5;
  border-radius: 12px;
  cursor: pointer;
  appearance: none;
  transition: background 0.3s;
}

input[type="range"]:hover::-webkit-slider-thumb {
  background: #6366f1;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #6366f1;
  border-radius: 12px;
  cursor: pointer;
  transition: background 0.3s;
}

input[type="range"]:hover::-moz-range-thumb {
  background: #6366f1;
}
.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

.toggle-checkbox:checked {
  right: 0;
  transform: translateX(100%);
  background-color: #3182ce;
}

.toggle-checkbox:checked + .toggle-label > span {
  color: #3182ce;
  transform: translateX(-100%);
}

.toggle-checkbox {
  top: -0.1rem;
  left: -0.1rem;
  width: 2rem;
  height: 2rem;
  transition: all 0.3s linear;
  background-color: #fdba74;
  content: "";
  visibility: visible;
  position: absolute;
  border-radius: 50%;
}

.toggle-label > span {
  transition: all 0.3s ease-in-out;
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent; /* Change this to transparent */
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s ease-in-out; /* Smooth fade in and out */
  padding: auto;
}
table {
  width: 100%;
  border-collapse: collapse;
  font-size: 18px; /* Increased base font size for better readability on larger screens */
  height: 100%;
}

th,
td {
  padding: 10px; /* Reduced padding for better space utilization */
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: #ffffff;
}

th {
  background-color: #ff4601;
  color: white;
}

span.text-green-500 {
  color: #00b140;
}

span.text-red-500 {
  color: #e02424;
}

@media screen and (max-width: 768px) {
  table {
    font-size: 12px; /* Reduced font size on mobile devices */
  }
  th,
  td {
    padding: 5px; /* Reduced padding on mobile devices */
  }
}

.custom-logo {
  width: 100%;
  object-fit: scale-down;
}
@media (min-width: 768px) {
  .player-img-container {
    right: 4rem;
  }
}

.player-search .search-bar {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-search .search-bar input {
  padding: 10px;
  width: 60%;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.player-search .search-bar button {
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Hover effect transition */
}

.player-search .search-bar button:hover {
  background-color: #f0f0f0; /* Change the color as needed for hover effect */
}

/* Mobile view */
/* Mobile view */
/* Mobile view */
@media (max-width: 768px) {
  .player-search .search-bar-wrapper {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 10; /* May adjust if needed */
  }
  .player-search .search-bar-wrapper .search-bar {
    background-color: white;
  }
  .player-search .grid {
    padding-bottom: 60px; /* Space for the search bar */
  }
}

/* Desktop view */
@media (min-width: 769px) {
  .player-search .search-bar {
    margin-bottom: 10px;
    text-align: center;
    background-color: white;
  }

  .player-search .grid-with-results {
    padding-top: 20px; /* Space between search bar and results, adjust as needed */
  }
}

/* Define styles for smaller screens */
@media (max-width: 1307px) {
  .player-img-container {
    left: 1.2rem;
    right: 0rem;
  }
}
.bg-custom {
  background-size: cover;
  background-position: center;
}

.bg-orange {
  background-color: #f23d00;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.input-box {
  padding: 15px;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

ReactModal__Content--after-open {
  transform: scale(1);
}
.ReactModal__Content {
  --desired-height: calc(100% - 30px);
  position: relative;
  margin: auto;
  width: 100%; /* Adjusted to full width for mobile */
  max-width: 800px;
  outline: none;
  border: none;
  justify-content: center;
  overflow: auto;
  border-radius: 12px;
  transition: transform 0.25s ease-in-out;
  transform: scale(1.05);
  height: var(--desired-height);
  transform: translateY(0%); /* Prevent translation upwards */
  display: flex;
  justify-content: center; /* Horizontally center the child content */
  align-items: center; /* Vertically center the child content */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Optional, to prevent scroll */
}
/* On modal open */
.ReactModal__Content--after-open {
  transform: scale(1);
}

/* On modal close */
.ReactModal__Content--before-close {
  transform: scale(1.05);
}

.league-selector-container {
  display: flex;
  justify-content: flex-end;
}

.buttonInteractive:hover {
  background-color: #f23d00; /* slightly darker color */
  transform: translateY(1px); /* small push down effect */
}

.buttonInteractive:active {
  transform: translateY(2px); /* further push down effect for "pressing" */
}

.buttonInteractive {
  transition: transform 0.15s ease, background-color 0.15s ease;
  cursor: pointer;
}

/* Default layout for larger screens */
.finance-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.finance-card {
  flex: 1;
  margin: 10px;
  box-sizing: border-box;
}

/* Mobile layout: Stack the cards inside a single box */
@media (max-width: 640px) {
  .ReactModal__Content {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%; /* Making the modal wider */
  }

  .finance-container {
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
  }
  .timeline-date {
    font-size: 0.8rem;
  }

  .timeline-image {
    width: 2rem;
    height: 2rem;
  }
  .timeline-font-semibold {
    font-size: 0.9rem;
  }
  .custom-modal {
    position: relative; /* Ensures that child elements can be absolutely positioned within */
  }

  .close-button {
    position: absolute; /* Positions the button relative to the modal container */
    top: 0; /* Aligns the button with the top of the container */
    right: 0; /* Aligns the button with the right of the container */
    z-index: 50; /* Ensures the button appears above other content */
    padding: 1.5rem; /* Adds some spacing around the button */
    padding-top: 1rem; /* Adds some spacing around the button */
  }

  .modal-container {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    /* Optionally, to center content within the modal itself */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .finance-card {
    margin-bottom: 10px; /* Spacing between stacked cards */
  }
  /* Hide the entire graph */
  .graph-container {
    display: none;
  }
  .safe-area-container {
    padding-bottom: 14px;
  }
  /* Show only the line */

  .cookie-banner {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff; /* or any color that fits your design */
    color: #000; /* text color */
    text-align: center;
    padding: 10px;
    z-index: 1000; /* make sure it's above other elements */
  }

  /* Adjusting table styles for mobile */
  table.text-white {
    font-size: 0.8rem; /* Consider reducing font size */
  }

  table.text-white th,
  table.text-white td {
    padding: 2px 4px; /* Reduced padding */
  }

  /* Make the modal wider on mobile */
  .bg-gray-900 {
    width: 100%; /* Adjust this value as needed to make it wider */
  }
}

.overlay-content {
  max-height: 80vh; /* Adjust as needed */
  overflow-y: auto; /* Allows scrolling within the overlay */
}

.player-comparison-card {
  max-width: 300px; /* Adjust width as needed */
  margin: 10px auto; /* Centers the card and adds spacing */
  display: block; /* Stack cards vertically */
}

.player-list {
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  gap: 10px; /* Spacing between cards */
}

.player-traits {
  margin-top: 10px; /* Adjust as needed */
  font-size: 14px; /* Match your design */
  color: #ffffff; /* Match the text color */
}

.trait {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Space between traits */
}

.trait-icon {
  margin-right: 0.5rem; /* 8px right margin for the icon */
  /* Add color or size adjustments as needed */
}

.view-container {
  transition: transform 0.3s ease-out;
  display: flex;
  width: 100%;
  overflow-x: hidden;
}

@media (hover: hover) and (pointer: fine) {
  .hover\:scale-105:hover {
    transform: scale(1.05);
  }
}

@media (hover: none) {
  .hover\:scale-105:hover {
    transform: none;
  }
}